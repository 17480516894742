export const team = [
    {
        img: 'Reto.jpg',
        name: 'Reto Bossard',
        funktion: 'CEO',
        funktion2: 'Leiter Abacus (OBT AG)',
        gruppe: 'Ge­schäfts­leitung'
    },
    {
        img: 'NL.jpg',
        name: 'Nadine Cerutti Lang',
        funktion: 'Mitglied der GL, Personal',
        funktion2: 'Projektleiterin HR Systeme',
        gruppe: 'Ge­schäfts­leitung'
    },
    {
        img: 'MM.jpg',
        name: 'Mike Monticoli',
        funktion: 'Mitglied der GL, Projekte',
        funktion2: 'Projektleiter HR Systeme',
        gruppe: 'Ge­schäfts­leitung'
    },
    {
        img: 'MH.jpg',
        name: 'Marcel Hungerbühler',
        funktion: 'Mitglied der GL, Verkauf',
        funktion2: 'Projektleiter HR Systeme',
        gruppe: 'Ge­schäfts­leitung'
    },
    /*{
        img: 'AL.jpg',
        name: 'Annette Lehmann',
        funktion: 'Finanzen/Personal',
        gruppe: 'Interne Services'
    },*/
    {
        img: 'ML.jpg',
        name: 'Martin Lehmann',
        funktion: 'VR-Mitglied',
        funktion2: 'Senior Berater',
        gruppe: 'Expertenteam'
    },
    {
        img: 'DS.jpg',
        name: 'Daniel Strassmann',
        funktion: 'Leiter Abacus HR (OBT AG)',
        gruppe: 'Expertenteam'
    },
    {
        img: 'PP.jpg',
        name: 'Patrick Pfrunder',
        funktion: 'Projektleiter HR Systeme',
        funktion2: 'Informatiker WIS/HWV',
        gruppe: 'Expertenteam'
    },
    {
        img: 'PR.jpg',
        name: 'Philipp Renggli',
        funktion: 'Projektleiter HR Systeme',
        funktion2: 'Master of Advanced Studies in Business Information Technology HSLU',
        gruppe: 'Expertenteam'
    },
    /*{
        img: 'PW.jpg',
        name: 'Pius Wallimann',
        funktion: 'Projektleiter HR Systeme',
        funktion2: 'Eidg. dipl. Organisator',
        gruppe: 'Expertenteam'
    },*/
    {
        img: 'RC.jpg',
        name: 'René Christen',
        funktion: 'Projektleiter HR Systeme',
        funktion2: 'Bachelor of Science in Wirtschaftsinformatik HSLU',
        gruppe: 'Expertenteam'
    }, 
    {
        img: 'VD.jpg',
        name: 'Vlora Nuaj',
        funktion: 'Projektleiterin HR Systeme',
        funktion2: 'Bachelor of Science in Wirtschaftsinformatik HSLU',
        gruppe: 'Expertenteam'
    },
    {
        img: 'FK.jpg',
        name: 'Franz Keiser',
        funktion: 'Projektleiter HR Systeme',
        funktion2: '-',
        gruppe: 'Expertenteam'
    },
    {
        img: 'RB.jpg',
        name: 'Rico Bürgler',
        funktion: 'Projektleiter HR Systeme',      
        funktion2: 'Bachelor of Science in Wirtschaftsinformatik HSLU',
        gruppe: 'Expertenteam'
    },
    {
        img: 'NK.jpg',
        name: 'Noel Kaeslin',
        funktion: 'Projektleiter HR Systeme',
        funktion2: 'Bachelor of Science in Wirtschaftsinformatik HSLU',
        gruppe: 'Expertenteam'
    },
    {
        img: 'MI.jpg',
        name: 'Michael Imhof',
        funktion: 'Projektleiter HR Systeme',
        funktion2: 'Bachelor of Science in Informatik HSLU',
        gruppe: 'Expertenteam'
    },
    {
        img: 'MGA.jpg',
        name: 'Maya Gander',
        funktion: 'Projektleiterin HR Systeme',
        funktion2: 'Master of Science in Wirtschaftsinformatik HSLU',
        gruppe: 'Expertenteam'
    },
    {
        img: 'KR.jpg',
        name: 'Kai Reichl',
        funktion: 'Projektleiter HR Systeme',
        funktion2: 'Cand. Bachelor of Science in Wirtschaftsinformatik',
        gruppe: 'Expertenteam'
    },
    {
        img: 'NoPicF.jpg',
        name: 'Saskia Rindisbacher',
        funktion: 'Junior Projektleiterin HR Systeme',
        funktion2: 'HR-Fachfrau mit eidg. Fachausweis',
        gruppe: 'Expertenteam'
    },
    {
        img: 'MG.jpg',
        name: 'Michael Galli',
        funktion: 'Leiter Entwicklung',
        funktion2: 'Master of Science in Engineering HSLU',
        gruppe: 'Entwicklung'
    },
    {
        img: 'DB.jpg',
        name: 'Diego Boesel',
        funktion: 'Entwickler HR Systeme',
        funktion2: 'Masters in Space Science and Technology TKK',
        gruppe: 'Entwicklung'
    },
    /*{
        img: 'KS.jpg',
        name: 'Kota Schnider',
        funktion: 'Entwickler HR Systeme',
        funktion2: 'Informatiker EFZ Applikationsentwicklung',
        gruppe: 'Entwicklung'
    },*/
    {
        img: 'SS.jpg',
        name: 'Saket Satrasala',
        funktion: 'Entwickler HR Systeme',
        funktion2: 'Entwickler Digitales Business EFZ in Ausbildung',
        gruppe: 'Entwicklung'
    },
    {
        img: 'FR.jpg',
        name: 'Fabian Remschak',
        funktion: 'Entwickler HR Systeme',
        funktion2: 'Bachelor of Science in Wirtschaftsinformatik ZHAW',
        gruppe: 'Entwicklung'
    },
]